<template>
	<div class="body">
		<img class="logo" src="@/assets/image/login/logo.png"></img>
		<img class="bg" src="@/assets/image/login/bg_content.png"></img>
	
		<!-- <div class="manual">
			<ins>?</ins>操作手册
			<div>
				<ul>
					<a href="" target="_blank"><li>业主单位使用手册</li></a>
					<a href="" target="_blank"><li>企业用户使用手册</li></a>
					<a href="" target="_blank"><li>金融机构使用手册</li></a>
				</ul>
			</div>
		</div> -->
	
		<div class="login">
			<div class="title flex-center">
				<img src="@/assets/image/login/title.png">
			</div>
			<div class="item">
				<label>姓<i></i>名：</label>
				<input v-model="username" type="text" @keydown.enter="loginHandle">
			</div>
			<div class="item">
				<label>手机号：</label>
				<input v-model="phone" type="text" @keydown.enter="loginHandle">
			</div>
			<button @click="loginHandle">立即登录</button>
			<div class="link flex-between-center">
				<a @click="qrcodeHandle">浙政钉扫码登录</a>
				<p>我单位还没有账号，<router-link to="/register">业主单位登记</router-link></p>
			</div>
		</div>
		
		<div class="copyright">
			<p>浙江银江智能信息技术有限公司 提供技术支持</p>
			<p><span>平台客服邮箱：yjszbh@163.com</span><span>浙ICP备2022015443号-1</span></p>
		</div>
		
		<smscode ref="sms" @getSmscode="getSmscode" @submit="validHandle"></smscode>
		
		<c-dialog ref="qrcode" fadein title="浙政钉扫码登录" width="450" height="auto" :button="false">
			<div class="ding">
				<iframe v-if="showQrcode" :src="dingLogin(true)"></iframe>
			</div>
		</c-dialog>
	</div>
</template>

<script>
	import smscode from '@/components/module/smscode.vue'
	import fun from '@/assets/script/function.js'
	
	export default {
		components: {
			smscode
		},
		
		data() {
			return {
				username: '',
				phone: '',
				showQrcode: false
			};
		},
		
		mounted() {
			window.addEventListener('message', res => {
				if (res.data.code) {
					this.request({
						url: '/zzd/login',
						data: {
							type: 2,
							code: res.data.code
						},
						loading: true,
						success: data => {
							fun.setCookie('session_id', data.sessionId);
							this.$store.commit('setUserInfo', data);
							this.navigateTo('/');
						},
						error: function (data,b,c) {
							alert("系统出错！");
						}
					});
				}
			});
		},
		
		methods: {
			loginHandle() {
				if (!this.username.trim() || !this.phone.trim()) {
					this.$message({
						text: '请输入姓名和手机号'
					});
					return;
				}
				
				this.$refs.sms.getSmscode();
			},
			
			getSmscode(next) {
				this.request({
					url: '/user/yzdw/login_code',
					data: {
						user_realname: this.username,
						user_phone: this.phone
					},
					loading: true,
					success: data => {
						this._token = data;
						next();
						this.$refs.sms.open(this.phone);
					}
				});
			},
			
			validHandle(code, next) {
				if (!code) {
					this.$message({
						text: '请输入短信验证码'
					});
					return;
				}
				
				this.request({
					url: '/user/yzdw/login',
					data: {
						code_token: this._token,
						verify_code: code
					},
					loading: true,
					success: data => {
						fun.setCookie('session_id', data.sessionId);
						this.$store.commit('setUserInfo', data);
						this.navigateTo('/');
					}
				});
			},
			
			qrcodeHandle() {
				this.showQrcode = true;
				this.$refs.qrcode.open();
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.body {
		height: 100vh;
		background-color: #003494;
		background-image: url('~@/assets/image/login/bg.jpg');
		background-position: top left;
		background-size: 1920px auto;
		background-repeat: no-repeat;
	}
	
	.logo {
		position: fixed;
		left: 50px;
		top: 40px;
		height: 36px;
	}
	
	.bg {
		z-index: 1;
		position: fixed;
		left: 0;
		top: 0;
		width: 1920px;
	}

	.manual {
		z-index: 2;
		position: fixed;
		top: 48px;
		right: 60px;
		font-size: 18px;
		color: #fff;
		cursor: pointer;
		
		&:hover div {
			display: block;
		}
		
		ins {
			display: inline-block;
			vertical-align: 1px;
			width: 16px;
			height: 16px;
			border: 1px solid #fff;
			border-radius: 100%;
			margin-right: 10px;
			line-height: 16px;
			font-size: 12px;
			text-align: center;
			text-decoration: none;
		}
		
		div {
			display: none;
			position: absolute;
			right: 0;
			top: 100%;
			width: 170px;
			padding-top: 10px;
		}
		
		ul {
			position: relative;
			padding: 10px 0;
			background: #204FAB;
			
			&:before {
				content: '';
				position: absolute;
				right: 10px;
				top: -5px;
				border-bottom: 5px solid #204FAB;
				border-left: 5px solid rgba(0,0,0,0);
				border-right: 5px solid rgba(0,0,0,0);
			}
			
			li {
				list-style-type: none;
				padding: 0 15px;
				line-height: 36px;
				font-size: 15px;
				color: #BCC5E0;
				
				&:hover {
					color: #fff;
					background: #1B418B;
				}
			}
		}
	}

	.login {
		z-index: 9;
		position: absolute;
		right: 130px;
		top: 50%;
		width: 620px;
		height: 420px;
		margin-top: -210px;
		overflow: hidden;
		background-image: url('~@/assets/image/login/login_bg.png');
		background-size: 100%;
		
		.title {
			height: 40px;
			margin: 50px 0 40px;
		}
		
		.item {
			margin-bottom: 22px;
			font-size: 0;
			
			label {
				display: inline-block;
				vertical-align: top;
				width: 130px;
				line-height: 46px;
				font-size: 18px;
				text-align: right;
				color: #fff;
				
				i {
					display: inline-block;
					width: 1em;
				}
			}
			
			input {
				display: inline-block;
				vertical-align: top;
				box-sizing: border-box;
				width: 400px;
				height: 46px;
				border: none;
				padding: 0 10px;
				font-size: 16px;
				color: #fff;
				background: #002774;
				box-shadow: 1px 1px 0 0 rgba(255,255,255,.21);
			}
		}
		
		button {
			display: block;
			width: 400px;
			height: 60px;
			border: none;
			border-radius: 30px;
			margin-left: 130px;
			margin-top: 32px;
			font-size: 22px;
			color: #fff;
			background: #1492FF;
			cursor: pointer;
			
			&:hover {
				opacity: .9;
			}
		}
		
		.link {
			width: 400px;
			margin-left: 130px;
			margin-top: 25px;
			font-size: 16px;
			color: #3296FA;
			
			a {
				color: #FEDD00;
			}
		}
	}
	
	.copyright {
		z-index: 1;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 28px;
		line-height: 62px;
		font-size: 13px;
		color: #1492FF;
		background: #002774;
		
		p {
			float: left;
			
			&:nth-child(2) {
				float: right;
			}
			
			span, a {
				margin-left: 1em;
				text-decoration: none;
				color: #1492FF;
			}
		}
	}
	
	.ding {
		height: 300px;
		overflow: hidden;
		
		iframe {
			width: 100%;
			height: 100%;
			border: none;
			transform: translate(0, -34px);
		}
	}
</style>